import {Pipe, PipeTransform} from '@angular/core';
import {Punch} from "../../domain/models";

@Pipe({
  name: 'filterListByBib'
})
export class FilterListByBibPipe implements PipeTransform {

  transform(items: Punch[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    const excludeRanges: [number, number][] = [];
    const includeRanges: [number, number][] = [];
    const queries = searchText.split(',').map(q => q.trim());
    for (const q of queries) {
      let isExclusion = false;
      let range: number[];

      if (q.startsWith('!')) {
        isExclusion = true;
      }
      const cleanedQuery = q.replace('!', '').trim();

      if (cleanedQuery.includes('-')) {
        range = cleanedQuery.split('-').map(x => +x);
        if (range.length === 2 && range[0] <= range[1]) {
          if (isExclusion) {
            excludeRanges.push([range[0], range[1]]);
          } else {
            includeRanges.push([range[0], range[1]]);
          }
        }
      } else {
        const number = +cleanedQuery;
        if (isExclusion) {
          excludeRanges.push([number, number]);
        } else {
          includeRanges.push([number, number]);
        }
      }
    }

    function check(ranges: [number, number][], value: number) {
      for (const [start, end] of ranges) {
        if (value >= start && value <= end) {
          return true;
        }
      }
      return false;
    }

    return items.filter(punch => {
      const bibValue = +punch.bib;
      return !check(excludeRanges, bibValue) && check(includeRanges, bibValue);
    });
  }

}
