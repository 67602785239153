import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DevicesStateComponent} from './components/devices-state/devices-state.component';
import {PunchesComponent} from "./components/punches/punches.component";
import {DevicePunchesComponent} from "./components/device-punches/device-punches.component";
import {ConfigsComponent} from "./components/configs/configs.component";
import {AuthPageTokenComponent} from "./components/auth-page-token/auth-page-token.component";
import {SettingsComponent} from "./components/settings/settings.component";

const routes: Routes = [
  {path: '', redirectTo: '/devices', pathMatch: 'full'},
  {path: 'devices', component: DevicesStateComponent},
  {path: 'auth', component: AuthPageTokenComponent},
  {path: 'punches', component: PunchesComponent},
  {path: 'configs', component: ConfigsComponent},
  {path: 'settings', component: SettingsComponent},
  {path: 'devices/:id/punches', component: DevicePunchesComponent},
  // {path: 'devices/:id', component: DeviceInfoComponent},
  // {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
