<div class="navigation-bar">
  <nav routerLink="/devices">
    <a class="nav-item" routerLink="/devices">Устройства</a>
  </nav>
  <nav routerLink="/punches">
    <a class="nav-item" routerLink="/punches">Все проходы</a>
  </nav>
  <nav  routerLink="/configs">
    <a class="nav-item" routerLink="/configs">Конфиги</a>
  </nav>
  <nav  class="right-item">
    <a class="nav-item btn" routerLink="/settings"><i id="btn-settings" class="fa fa-cog"></i></a>
    <a class="nav-item btn" routerLink="/auth"><i id="btn-punches" class="fa fa-user"></i></a>
  </nav>
</div>
<div>
  <router-outlet></router-outlet>
</div>
