<div class="div-content">
  <div class="div-input-token">
    <h5>From timestamp (UTC)</h5>
    <div class="div-input-token-row">
      <input [value]="from"
             [(ngModel)]="from"
             autofocus>
      <button class="button-yellow" (click)="updateTimestamp()">Refresh Time (-5h)</button>
      <button class="button-yellow" (click)="updateTimestampToCurrent()">Set current time</button>
    </div>
    <button class="button-main" (click)="apply()">Apply</button>
  </div>
</div>
