import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OpenbandService} from "../../services/openband/openband.service";
import {NotificationService} from "../../services/notifications/notification.service";
import {Title} from "@angular/platform-browser";
import {PreferencesService} from "../../services/preferences/preferences.service";
import {AppPreferences} from "../../domain/models";

@Component({
  selector: 'app-auth-page-token',
  templateUrl: './auth-page-token.component.html',
  styleUrls: ['./auth-page-token.component.scss']
})
export class AuthPageTokenComponent {
  token: string = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: OpenbandService,
    private notifyService: NotificationService,
    private titleService: Title,
    private preferencesService: PreferencesService,
  ) {
    this.token = preferencesService.appPreferences.token
    titleService.setTitle("Authorization")
  }

  validator(str) {
    return !/[^\u0000-\u00ff]/g.test(str);
  }

  authorize() {
    if (this.validator(this.token) == false) {
      this.notifyService.showError("Token contains non ISO-8859-1 code point")
      return
    }
    var prefs = new AppPreferences();
    prefs.token = this.token
    this.preferencesService.setAppPreferences(prefs)
    this.router.navigateByUrl("")
  }
}
