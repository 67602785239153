<>
<table class="table">
  <thead>
  <tr>
    <th>Device ID</th>
    <th>Name</th>
    <th>App version</th>
    <th>Model</th>
    <th>Temperature</th>
    <!--        <th>System version</th>-->
    <th>Platform</th>
    <!--    <th (click)="sortByLastActiveTime()" scope="col" [appSort]="data" data-order="desc"-->
    <!--        data-name="lastActiveTime">Последняя активность-->
    <!--    </th>-->
    <th>Online</th>
    <th>Config</th>
    <th>Actions</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let device of data; let i = index" style="background-color: #000000;">
    <tr (click)="selectDevice(device)">
      <!--      <tr (click)="state.expanded = !state.expanded" style="background-color: #000000;">-->
      <td>{{device?.id}}</td>
      <td>
        <input type="text" class="form-control" id="name" required [(ngModel)]="device.name" name="name"
               placeholder="" #name="ngModel" (input)="updateDevice(device)">
      </td>
      <!--      <td>-->
      <!--        {{state?.lastActiveTime}} (+{{state?.lastActiveTimeInSeconds}})-->
      <!--      </td>-->
      <td><p>{{device?.meta?.appVersion}}</p></td>
      <td><p>{{device?.meta?.model}}</p></td>
      <td><p>{{getDeviceTemperature(device)}}</p></td>
      <!--      <td><p>{{device?.meta?.manufacturer}}</p></td>-->
      <!--      <td><p>{{device?.meta?.systemVersion}}</p></td>-->
      <td><p>{{device?.meta?.platform}}</p></td>
      <td>
        <span *ngIf="device?.isOnline == true" class="logged-in">●</span>
        <span *ngIf="!device?.isOnline" class="logged-out">●</span>
      </td>
      <td>
        <div class="form-group">
          <label>{{device.config.name}} ({{device.config?.id}})</label>
          <select
            (change)="linkConfig(device,device.config)"
            [(ngModel)]="device.config"
            class="form-control" id="control-points">
            <option *ngFor="let c of configs" [ngValue]="c">{{c?.name}} ({{c?.id}})</option>
          </select>
        </div>
      </td>
      <td>
        <button class="btn" (click)="open2(content2, device)"><i id="btn-plus" class="fa fa-plus"></i></button>
        <button class="btn" (click)="expandDevice(device)"><i id="btn-info" class="fa fa-info-circle"></i></button>
        <button class="btn" routerLink="/devices/{{device?.id}}/punches"><i id="btn-punches" class="fa fa-list"></i>
        </button>
        <button class="btn" *ngIf="isDeviceHaveNiceLocation(device) == false" (click)="syncDeviceLocation(device)"><i
          class="fa fa-location-arrow" style="color:red"></i></button>
        <button class="btn" *ngIf="isDeviceHaveNiceLocation(device) == true"
                (click)="openLocationDialog(content, device)"><i
          class="fa fa-location-arrow" style="color:green"></i></button>
      </td>
    </tr>
    <ng-container *ngIf="device?.expanded">
      <tr *ngFor="let p of device?.commands">
        <td class="td-e">{{device?.commands?.indexOf(p) + 1}}</td>
        <td class="td-e">{{p?.uuid}}</td>
        <td class="td-e"></td>
        <td class="td-e"></td>
        <td class="td-e"></td>
        <td class="td-e"></td>
        <td class="td-e">{{p?.action}} ({{p?.body}})
        </td>
        <td class="td-e"></td>
        <td class="td-e">
          <button class="btn" (click)="deleteCommand(device?.id, p)"><i class="fa fa-trash"></i></button>
        </td>
      </tr>
    </ng-container>
  </ng-container>
  </tbody>
</table>


<ng-template #content2 let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Добавление команды
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <h6>
          Примеры команд:
        </h6>
        <p>
          <b>resend_all_to_zone_results</b> - переотправка в zr<br>
          <b>resend_all_to_openband</b> - переотправка в openband<br>
          <b>resend_all_to_orgeo</b> - переотправка в orgeo<br>
          <b>restart_tcp_server</b> - перезапуск tcp сервера<br>
          <b>sync_config</b> - синхронизация конфига (указать id конфига в данных)<br>
          <b>send_bib</b> - отправка номера с устройства (указать номер в данных)<br>
        </p>
        <label for="command">Команда</label>
        <select class="form-control" id="selected-point" [(ngModel)]="command" name="command">
          <option *ngFor="let c of commands" [ngValue]="c">{{c}}</option>
        </select>
        <!--        <input type="text" class="form-control" id="uploadPeriod" [(ngModel)]="command" name="minor">-->
        <label for="body" style="margin-top: 10px">Данные</label>
        <input type="text" class="form-control" id="body" [(ngModel)]="body" name="body">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Create click')">
      Создать
    </button>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Выберите действие
    </h4>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <button type="button" class="btn btn-outline-dark button-center"
              (click)="syncDeviceLocation(currentDevice,modal)">
        Обновить местоположение
      </button>
      <button type="button" class="btn btn-outline-dark button-center"
              (click)="openDeviceLocation(currentDevice,modal)">
        Посмотреть местоположение
      </button>
    </div>
  </div>
  <!--  <div class="modal-footer">-->
  <!--    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Create click')">-->
  <!--      Создать-->
  <!--    </button>-->
  <!--  </div>-->
</ng-template>

