import {Pipe, PipeTransform} from '@angular/core';
import {Punch} from "../../../domain/models";

@Pipe({
  name: 'filterListByPoint'
})
export class FilterListByPointPipe implements PipeTransform {

  transform(items: Punch[], point: string): any[] {
    return items.filter(it => {
      if (point == "-" || point == "" || point == null) return it
      else return it?.timingPoint.includes(point);
    })
  }

}
