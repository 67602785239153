import {Component} from '@angular/core';
import {Config, OrgeoConfig, RfidReaderBoxConfig, RfidReaderConfig, ZoneResultConfig} from "../../domain/models";
import {ActivatedRoute, Router} from "@angular/router";
import {OpenbandService} from "../../services/openband/openband.service";
import {NotificationService} from "../../services/notifications/notification.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.scss']
})
export class ConfigsComponent {
  data: Config[] = [];
  private subscription

  configToCreate: Config = new Config();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: OpenbandService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private titleService: Title,
  ) {
    titleService.setTitle("Configs")
    const zrConfig = new ZoneResultConfig();
    zrConfig.ip = "collector.results.zone"
    zrConfig.port = 3355
    this.configToCreate.zoneResults = zrConfig
    this.configToCreate.orgeo = new OrgeoConfig()
    this.configToCreate.rfidReader = new RfidReaderConfig()
    this.configToCreate.rfidReaderBox = new RfidReaderBoxConfig()
  }

  containsObject(obj, list: Config[]) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].id === obj.id) {
        return true;
      }
    }

    return false;
  }

  loadConfigs() {
    this.api.getConfigs().subscribe((res) => {
      res?.forEach(x => {
        if (this.containsObject(x, this.data) == false) {
          this.data.push(x)
        }
      })
      this.data = this.data.sort( (a,b) => {
        if (a.id > b.id) return 1
        else return -1
      })
    })
  }

  ngOnInit(): void {
    this.loadConfigs()
    // this.subscription = interval(5000).subscribe((val) => {
    //   this.loadPunches()
    // })
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }

  updateConfig(state: Config) {
    this.api.updateConfig(state.id, state).subscribe((res) => {
      this.notifyService.showSuccess("Конфиг " + res.name + "(" + res.id + ") обновлён")
    })
  }

  open2(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      console.log(JSON.stringify(this.configToCreate))
      this.api.createConfig(this.configToCreate).subscribe(
        result => {
          this.notifyService.showSuccess("Config " + result.name + " (" + result.id + ") created")
          this.loadConfigs()
        },
        error => {
          this.notifyService.showError("Create config failed")
        }
      )
    }, (reason) => {

    });
  }
}
