<div class="div-default">
  <button class="button-main button-center" (click)="open2(content)">Создать конфиг</button>
</div>
<table class="table">
  <thead>
  <tr>
    <th>ID</th>
    <th>Name</th>
    <th>Orgeo</th>
    <th>RFID</th>
    <th>ZR</th>
    <th>ReaderBox</th>
    <th>Timing settings</th>
    <th>Actions</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let state of data" style="background-color: #000000;">
    <tr>
      <td>{{state?.id}}</td>
      <td>{{state?.name}}</td>
      <td>
        <div class="form-group form-check">
          <div>
            <input type="checkbox" class="form-check-input" id="enabled" [(ngModel)]="state.orgeo.enabled"
                   name="published">
            <label class="form-check-label" for="published">Включено</label>
          </div>
          <div>
            <input type="checkbox" class="form-check-input" id="sendLegacyEnabled"
                   [(ngModel)]="state.orgeo.sendLegacyEnabled"
                   name="published">
            <label class="form-check-label" for="published">Send legacy enabled</label>
          </div>
          <div>
            <input type="checkbox" class="form-check-input" id="sendEnabled" [(ngModel)]="state.orgeo.sendEnabled"
                   name="published">
            <label class="form-check-label" for="published">Send enabled</label>
          </div>
          <div>
            <label class="form-check-label" for="published">url</label>
            <input type="text" class="form-control" id="url" [(ngModel)]="state.orgeo.url" name="published">
          </div>
          <div>
            <label class="form-check-label" for="published">antenna id</label>
            <input type="text" class="form-control" id="antennaId" [(ngModel)]="state.orgeo.antennaId" name="published">
          </div>
        </div>
      </td>
      <td>
        <div class="form-group form-check">
          <div>
            <input type="checkbox" class="form-check-input" id="enabled" [(ngModel)]="state.rfidReader.enabled"
                   name="published">
            <label class="form-check-label">Включено</label>
          </div>
          <div>
            <label class="form-check-label">IP</label>
            <input type="text" class="form-control" [(ngModel)]="state.rfidReader.ip">
          </div>
          <div>
            <label class="form-check-label">Port</label>
            <input type="text" class="form-control" [(ngModel)]="state.rfidReader.port">
          </div>
          <div>
            <label class="form-check-label">Event pin</label>
            <input type="text" class="form-control" [(ngModel)]="state.rfidReader.eventPin">
          </div>

          <div>
            <label class="form-check-label">Punch interval</label>
            <input type="text" class="form-control" [(ngModel)]="state.rfidReader.punchIntervalInMilliseconds">
          </div>
        </div>
      </td>
      <td>
        <div class="form-group form-check">
          <div>
            <input type="checkbox" class="form-check-input" [(ngModel)]="state.zoneResults.enabled">
            <label class="form-check-label">Включено</label>
          </div>
          <div>
            <label class="form-check-label">IP</label>
            <input type="text" class="form-control" [(ngModel)]="state.zoneResults.ip">
          </div>
          <div>
            <label class="form-check-label">Port</label>
            <input type="text" class="form-control" [(ngModel)]="state.zoneResults.port">
          </div>
          <div>
            <label class="form-check-label">Source</label>
            <input type="text" class="form-control" [(ngModel)]="state.zoneResults.source">
          </div>

          <div>
            <label class="form-check-label">Timing point</label>
            <input type="text" class="form-control" [(ngModel)]="state.zoneResults.timingPoint">
          </div>
          <div>
            <label class="form-check-label">Type</label>
            <input type="text" class="form-control" [(ngModel)]="state.zoneResults.type">
          </div>
          <div>
            <label class="form-check-label">Antenna</label>
            <input type="text" class="form-control" [(ngModel)]="state.zoneResults.antenna">
          </div>
        </div>
      </td>
      <td>
        <div class="form-group form-check">
          <div>
            <input type="checkbox" class="form-check-input" id="enabled" [(ngModel)]="state.rfidReaderBox.enabled"
                   name="published">
            <label class="form-check-label">Включено</label>
          </div>
          <div>
            <label class="form-check-label">IP</label>
            <input type="text" class="form-control" [(ngModel)]="state.rfidReaderBox.ip">
          </div>
          <div>
            <label class="form-check-label">Port</label>
            <input type="text" class="form-control" [(ngModel)]="state.rfidReaderBox.port">
          </div>
          <div>
            <label class="form-check-label">Event pin</label>
            <input type="text" class="form-control" [(ngModel)]="state.rfidReaderBox.eventPin">
          </div>

          <div>
            <label class="form-check-label">Punch interval</label>
            <input type="text" class="form-control" [(ngModel)]="state.rfidReaderBox.punchIntervalInMilliseconds">
          </div>
        </div>
      </td>
      <td>
        <div class="form-group form-check">
          <div>
            <label class="form-check-label">Source</label>
            <input type="text" class="form-control" [(ngModel)]="state.timingSettings.source">
          </div>

          <div>
            <label class="form-check-label">Timing point</label>
            <input type="text" class="form-control" [(ngModel)]="state.timingSettings.timingPoint">
          </div>
          <div>
            <label class="form-check-label">Type</label>
            <input type="text" class="form-control" [(ngModel)]="state.timingSettings.type">
          </div>
          <div>
            <label class="form-check-label">Antenna</label>
            <input type="text" class="form-control" [(ngModel)]="state.timingSettings.antenna">
          </div>
        </div>
      </td>
      <td>
        <button class="btn" (click)="updateConfig(state)"><i class="fa fa-save"></i></button>
      </td>
    </tr>
  </ng-container>
  </tbody>
</table>


<ng-template #content let-modal>
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">
      Добавление конфига
    </h3>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
<!--    <form>-->
      <div class="form-group">
        <h5>Основные параметры</h5>
        <label>Название конфига</label>
        <input type="text" class="form-control" [(ngModel)]="configToCreate.name">

        <h5 style="margin-top: 12px">Orgeo</h5>
        <div>
          <input type="checkbox" class="form-check-input" [(ngModel)]="configToCreate.orgeo.enabled"/>
          <label style="margin-left: 10px">Enabled</label>
        </div>
        <div>
          <input type="checkbox" class="form-check-input" [(ngModel)]="configToCreate.orgeo.sendLegacyEnabled"/>
          <label style="margin-left: 10px">Send legacy enabled</label>
        </div>
        <div>
          <input type="checkbox" class="form-check-input" [(ngModel)]="configToCreate.orgeo.sendEnabled"/>
          <label style="margin-left: 10px">Send enabled</label>
        </div>
        <div>
          <label class="form-check-label">URL</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.orgeo.url">
        </div>
        <div>
          <label class="form-check-label">Antenna ID</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.orgeo.antennaId">
        </div>

        <h5 style="margin-top: 12px">RFID Reader</h5>
        <div>
          <input type="checkbox" class="form-check-input" [(ngModel)]="configToCreate.rfidReader.enabled"/>
          <label style="margin-left: 10px">Enabled</label>
        </div>
        <div>
          <label class="form-check-label">IP</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.rfidReader.ip">
        </div>
        <div>
          <label class="form-check-label">Port</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.rfidReader.port">
        </div>
        <div>
          <label class="form-check-label">Event PIN</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.rfidReader.eventPin">
        </div>
        <div>
          <label class="form-check-label">Punch interval</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.rfidReader.punchIntervalInMilliseconds">
        </div>

        <h5 style="margin-top: 12px">Zone Results</h5>
        <div>
          <input type="checkbox" class="form-check-input" [(ngModel)]="configToCreate.zoneResults.enabled"/>
          <label style="margin-left: 10px">Enabled</label>
        </div>
        <div>
          <label class="form-check-label">IP</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.zoneResults.ip">
        </div>
        <div>
          <label class="form-check-label">Port</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.zoneResults.port">
        </div>
        <div>
          <label class="form-check-label">Source</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.zoneResults.source">
        </div>
        <div>
          <label class="form-check-label">Timing point</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.zoneResults.timingPoint">
        </div>
        <div>
          <label class="form-check-label">Type</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.zoneResults.type">
        </div>
        <div>
          <label class="form-check-label">Antenna</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.zoneResults.antenna">
        </div>

        <h5 style="margin-top: 12px">RFID Reader Box</h5>
        <div>
          <input type="checkbox" class="form-check-input" [(ngModel)]="configToCreate.rfidReaderBox.enabled"/>
          <label style="margin-left: 10px">Enabled</label>
        </div>
        <div>
          <label class="form-check-label">IP</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.rfidReaderBox.ip">
        </div>
        <div>
          <label class="form-check-label">Port</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.rfidReaderBox.port">
        </div>
        <div>
          <label class="form-check-label">Event PIN</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.rfidReaderBox.eventPin">
        </div>
        <div>
          <label class="form-check-label">Punch interval</label>
          <input type="text" class="form-control" [(ngModel)]="configToCreate.rfidReaderBox.punchIntervalInMilliseconds">
        </div>
      </div>
    <h5 style="margin-top: 12px">Timing settings</h5>
    <div>
      <label class="form-check-label">Source</label>
      <input type="text" class="form-control" [(ngModel)]="configToCreate.timingSettings.source">
    </div>
    <div>
      <label class="form-check-label">Timing point</label>
      <input type="text" class="form-control" [(ngModel)]="configToCreate.timingSettings.timingPoint">
    </div>
    <div>
      <label class="form-check-label">Type</label>
      <input type="text" class="form-control" [(ngModel)]="configToCreate.timingSettings.type">
    </div>
    <div>
      <label class="form-check-label">Antenna</label>
      <input type="text" class="form-control" [(ngModel)]="configToCreate.timingSettings.antenna">
    </div>
<!--    </form>-->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Create click')">
      Создать
    </button>
  </div>
</ng-template>

